/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import '../scss/style.scss';
// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
import $ from 'jquery';
import 'bootstrap';
import 'magnific-popup';
//import 'jquery.autocomplete';

//const $ = require('jquery');
//global.$ = $;

function showLoader() {
    $("#loader").removeClass("d-none");
}
window.showLoader = showLoader;

function hideLoader() {
    $("#loader").addClass("d-none");
}

$(document).ready(function(){

    if ($(window).width() > 769) {
        $('.navbar .dropdown').hover(function() {
            $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideDown();

        }, function() {
            $(this).find('.dropdown-menu').first().stop(true, true).delay(100).slideUp();

        });

        $('.navbar .dropdown > a').click(function() {
            location.href = this.href;
        });

    }

    $('.navbar ul.navbar-nav > .dropdown > a[href]').click(function() {
        var dropdown = $(this).next('.dropdown-menu');
        if (dropdown.length == 0 || $(dropdown).css('display') !== 'none') {
            if (this.href) {
                location.href = this.href;
            }
        }
    });

    //scroll to top button
    //Check to see if the window is top if not then display button
    $(document).scroll(function(){
        if ($(this).scrollTop() > 100) {
            $(".top-header-container").removeClass("height")
            $(".top-header-container").addClass("no-height")
            //$(".top-header-container").delay(400).hide();
            $("#logo-demeter").removeClass("d-lg-flex")
            $("#logo-demeter").hide();
            $('.scrollToTop').fadeIn();
            $('.navbar-brand').addClass("d-lg-flex");
            $('#navbarTogglerDemo01').removeClass('justify-content-center');
            $('#navbarTogglerDemo01').addClass('justify-content-end')
        } else {
            $(".top-header-container").removeClass("no-height");
            $(".top-header-container").addClass("height");
            //$(".top-header-container").show();
            $("#logo-demeter").addClass("d-lg-flex")
            $("#logo-demeter").show();
            $('.scrollToTop').fadeOut();
            $('.navbar-brand').removeClass("d-lg-flex");
            $('#navbarTogglerDemo01').addClass('justify-content-center');
            $('#navbarTogglerDemo01').removeClass('justify-content-end')
        }
    });

    //Click event to scroll to top
    $('.scrollToTop').click(function(){
        $('html, body').animate({scrollTop : 0},500);
        return false;
    });

    /*
    * Replace all SVG images with inline SVG
    */
    $('img.svg').each(function(){
        var $img = jQuery(this);
        var imgID = $img.attr('id');
        var imgClass = $img.attr('class');
        var imgURL = $img.attr('src');

        jQuery.get(imgURL, function(data) {
            // Get the SVG tag, ignore the rest
            var $svg = jQuery(data).find('svg');

            // Add replaced image's ID to the new SVG
            if(typeof imgID !== 'undefined') {
                $svg = $svg.attr('id', imgID);
            }
            // Add replaced image's classes to the new SVG
            if(typeof imgClass !== 'undefined') {
                $svg = $svg.attr('class', imgClass+' replaced-svg');
            }

            // Remove any invalid XML tags as per http://validator.w3.org
            $svg = $svg.removeAttr('xmlns:a');

            // Check if the viewport is set, if the viewport is not set the SVG wont't scale.
            if(!$svg.attr('viewBox') && $svg.attr('height') && $svg.attr('width')) {
                $svg.attr('viewBox', '0 0 ' + $svg.attr('height') + ' ' + $svg.attr('width'))
            }

            // Replace image with new SVG
            $img.replaceWith($svg);

        }, 'xml');

    });

    $("main").find("img").addClass("img-responsive");

    // $(".parallaxie").parallaxie();


    //cart
    $.ajax("/cart/count", {
        success:function(data, status) {
            let json = JSON.parse(data);
            $(".cartCount").text(json.count);
        }
    })

    $(".cart-preview").load("/cart/preview");


    $(".add-to-cart-button").on("click", function(e) {
        e.preventDefault();
        let quantityField;
        quantityField = "#quantity_"+$(this).data("id");
        let quantity;
        quantity = $(quantityField).val();
        if(quantity > 0) {
            $.ajax($(this).attr('href'),{
                method:"GET",
                data: {
                    quantity: quantity
                },
                success:function(response, status) {
                    let json;
                    json = JSON.parse(response);
                    let html;
                    html = '<div class="row"><div class="col-12 col-md-3">'+json.product.thumbnail+'</div><div class="col-12 col-md-6"><h5>'+json.product.name+'</h5><small>Artikel-Nr.: '+json.product.sku+'</small></div><div class="col-12 col-md-3 text-right"><h5>'+json.product.price+' &euro;</h5><small>Menge: '+json.product.quantity+'</small></div></div>';
                    $(".cart-preview").load("/cart/preview");
                    $("#cartModal").find(".modal-body").html(html);
                    $.ajax("/cart/count",{
                        success:function(data, status) {
                            json = JSON.parse(data);
                            $(".cartCount").html(json.count);
                        }
                    });
                    $('#cartModal').modal('toggle')
                }
            })
        }
    });

    $("#cart-content").load("/cart/cartcontent", null, function(){
        hideLoader();
    });

    $("#cart-content").on("click",".remove-from-cart-button", function(e){
        e.preventDefault();
        $.ajax($(this).attr('href'),{
            method:"GET",
            success:function(response, status) {
                $("#cart-content").load("/cart/cartcontent");
                $.ajax("/cart/count",{
                    success:function(data, status) {
                        let json = JSON.parse(data);
                        $(".cartCount").html(json.count);
                        $(".cart-preview").load("/cart/preview");
                    }
                });
            }
        })
    });

    $("#cart-content").on("click",".update-quantity-button",function(e){
        e.preventDefault();
        let quantityField = "#quantity_"+$(this).data("key");
        let quantity = $(quantityField).val();
        let key = $(this).data("key");
        if(quantity > 0) {
            $.ajax($(this).attr('href'),{
                method:"GET",
                data: {
                    key: key,
                    quantity: quantity
                },
                success:function(response, status){
                    $("#cart-content").load("/cart/cartcontent");
                    $.ajax("/cart/count",{
                        success:function(data, status) {
                            let json = JSON.parse(data);
                            $(".cartCount").html(json.count);
                        }
                    });
                    $(".cart-preview").load("/cart/preview");
                }
            })
        }
    })

    if ($('.history').length > 0){
        $( window ).scroll(function() {
            var curPosition = document.documentElement.scrollTop;
            $('.history-item').each(function () {
                var itemOffset = $(this).offset().top;
                var offset = $(window).height() / 10;

                if (curPosition > (itemOffset-offset)){
                    $(this).addClass("line-animation");
                    $(this).next().addClass("fade-line-animation");
                }
                else{
                    $(this).removeClass("line-animation");
                    $(this).next().removeClass("fade-line-animation");
                }
            });
        });
    }

    $('.carousel-multi').on('slide.bs.carousel', function (e) {
        var $e = $(e.relatedTarget);
        var idx = $e.index();
        var itemsPerSlide = 8;
        var totalItems = $(this).find('.carousel-item').length;

        if (idx >= totalItems-(itemsPerSlide-1)) {
            var it = itemsPerSlide - (totalItems - idx);
            for (var i=0; i<it; i++) {
                // append slides to end
                if (e.direction=="left") {
                    $(this).find('.carousel-item').eq(i).appendTo('.carousel-multi .carousel-inner');
                }
                else {
                    $(this).find('.carousel-item').eq(0).appendTo('.carousel-multi .carousel-inner');
                }
            }
        }
    });

    // var $el = $('input.search-field'),
    //     language = $el.data('language'), //optional
    //     country = $el.data('country'),
    //     $categoryEl = $el.closest('form').find('select.categories'),
    //     categories = []; //optional
    //
    // $el.autocomplete({
    //     minChars: 3,
    //     triggerSelectOnValidInput: false,
    //     lookup: function(term, done) {
    //         //update on every lookup because user may have changed the dropdown selection.
    //         categories = $categoryEl.val(); //optional
    //         $.getJSON(
    //             '/lucence-search/auto-complete',
    //             {
    //                 q: term,
    //                 language : language,
    //                 country: country,
    //                 categories: categories
    //             },
    //             function(data) {
    //                 var result = { suggestions : [] };
    //                 if(data.length > 0) {
    //                     $.each(data, function(index, suggestion) {
    //                         result.suggestions.push( {value : suggestion });
    //                     });
    //                 }
    //                 done(result);
    //             });
    //     },
    //     onSelect: function(result) {
    //         $el.val(result.value);
    //         $el.parents('form').submit();
    //     }
    // });

});







